

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import App from '../App';
import AppRoutes from './AppRoutes';
import AuthRoutes from './AuthRouth';
import { useAuth } from 'src/Modules/Auth/Core';
import RollbaseRoute from './RollbaseRoute';





const { PUBLIC_URL } = process.env;

const Navigations = () => {

    const { currentUser, auth } = useAuth()

console.log(currentUser)

    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes >
                <Route element={<App />}>

                    {
                    currentUser?.isAuthenticated  
                    // true
                    ? (

                        <>
                            <Route path="/*" element={<RollbaseRoute />} />
                            <Route index element={<Navigate to="/dashboard" />} />
                        </>
                    ) : (
                        <>
                            <Route path="npcl-admin/auth/*" element={<AuthRoutes />} />
                            <Route path='*' element={<Navigate to="npcl-admin/auth/" />} />
                        </>
                    )}

                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default Navigations;


