import axios, { AxiosError } from "axios";
import { API_URL, AUTH_URL } from "./apis";
import {
  getAuth,
  useAuth,
  setAuth,
  removeAuth,
  setCurrent,
} from "src/Modules/Auth/Core";

import { store } from "src/redux/store";
import {
  updateError,
  updateErrorCode,
  updateSuccessMess,
} from "src/redux/Reducers/errorHadlingSlicer";
import { ENUM_STATUS_CODE } from "src/helpers";
import { logoutApi, refreshTokenApi } from "./services";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const axiosWithToken = axios.create({
  baseURL: API_URL, // Replace with your API base URL
});

export const axiosWithTokenAuth = axios.create({
  baseURL: API_URL,
});

export const axiosWithTokenFileUpload = axios.create({
  baseURL: API_URL,
});

let isRefreshing = false; // Flag to track if a refresh token request is in progress
let refreshSubscribers: any[] = []; // Queue to hold requests waiting for the token refresh

const onRrefreshed = (newToken: string) => {
  // Notify all subscribers about the new token
  refreshSubscribers.forEach((callback) => callback(newToken));
  refreshSubscribers = [];
};

const addRefreshSubscriber = (callback: (newToken: string) => void) => {
  refreshSubscribers.push(callback);
};

const refreshToken = async () => {
  const token = getAuth();

  try {
    let postData = {
      refreshToken: token?.refreshToken,
    };
    let apiResp = await refreshTokenApi(postData);
    store.dispatch(updateErrorCode(apiResp.code));
    if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
      setCurrent({
        // ...apiResp?.data?.user,
        userResponse: apiResp?.data?.user,
        isAuthenticated: true,
        user_id: apiResp?.data?.user?.id,
        role: apiResp?.data?.user?.role,
        // ...apiResp?.data
      });

      setAuth({
        admin_token: apiResp.data?.tokens?.access?.token,
        accessTokenExp: apiResp.data?.tokens?.access?.expires,
        refreshTokenExp: apiResp.data?.tokens?.refresh?.expires,
        refreshToken: apiResp.data?.tokens?.refresh?.token,
        user_id: apiResp?.data?.user?.id,
        user_name: apiResp?.data?.user?.username,
        isVerified: apiResp?.data?.user?.isVerified,
        role: apiResp?.data?.user?.role,
        disom: apiResp?.data && apiResp?.data?.user?.disom,
        countryCode: apiResp?.data && apiResp?.data?.countryCode,
        state: apiResp?.data && apiResp?.data?.state,
        userCode: apiResp?.data && apiResp?.data?.user?.usercode,
      });

      return apiResp;
    } else {
      store.dispatch(updateErrorCode(apiResp.code));
      store.dispatch(updateError(apiResp.message));
      logoutHandling();
      return apiResp;
    }
  } catch (err) {
    let error = err as Error | AxiosError;
    if (axios.isAxiosError(error)) {
      store.dispatch(updateErrorCode(error.response?.data.code));
      store.dispatch(updateError(error.response?.data.message));
      logoutHandling();
    } else {
      store.dispatch(
        updateError("An unexpected error occurred. Please try again later.")
      );
      logoutHandling();
    }
  } finally {
  }
};

const logoutHandling = async () => {
  const token = getAuth();
  // setLogoutLoading(true);

  try {
    const postData = {
      refreshToken: token?.refreshToken && token?.refreshToken,
    };
    let apiResp = await logoutApi(postData);
    store.dispatch(updateErrorCode(apiResp.code));
    if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
      // logout();
      removeAuth();
      store.dispatch(updateSuccessMess("You’ve successfully logout out."));
    } else {
      store.dispatch(updateErrorCode(apiResp.code));
      store.dispatch(updateError(apiResp.message));
      // toast.error(apiResp.message)
    }
    return apiResp.data;
  } catch (err) {
    let error = err as Error | AxiosError;
    if (axios.isAxiosError(error)) {
      store.dispatch(updateErrorCode(error.response?.data.code));
      store.dispatch(updateError(error.response?.data.message));
      // toast.error(error.response?.data.message)
    } else {
      store.dispatch(updateError(error.message));
    }
  } finally {
    // setLogoutLoading(false);
  }
};

axiosWithToken.interceptors.request.use(
  async (config: any) => {
    const token = getAuth();

    const currentTime = dayjs().tz("Asia/Kolkata");
    const specificDayjsTime = dayjs(token?.accessTokenExp).tz("Asia/Kolkata");
    // Do something before each request, like adding authentication headers
    config.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      // "platform": "web"
    };

    if (
      specificDayjsTime.isSame(currentTime) ||
      specificDayjsTime.isBefore(currentTime)
    ) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const refreshResponse: any = await refreshToken();

          if (
            refreshResponse &&
            refreshResponse?.code === ENUM_STATUS_CODE?.SUCCESS
          ) {
            const newAccessToken = refreshResponse?.data?.tokens?.access?.token;
            config.headers["Authorization"] = `Bearer ${newAccessToken}`;
            onRrefreshed(newAccessToken);
          } else {
            // console.error("Failed to refresh the token");
            throw new Error(refreshResponse?.message || "Token refresh failed");
          }
        } catch (err) {
          // console.error("Error refreshing the token", err);
          return Promise.reject(err);
        } finally {
          isRefreshing = false; // Reset the flag
        }
      } else {
  
        return new Promise((resolve) => {
          addRefreshSubscriber((newToken: string) => {
            config.headers["Authorization"] = `Bearer ${newToken}`;
            resolve(config);
          });
        });
      }
    } else {
      // If the token is still valid, proceed with the current access token
      config.headers["Authorization"] = `Bearer ${token?.admin_token}`;
    }
    // config.headers['Authorization'] = `Bearer ${token?.admin_token}`;
    return config;
  },
  (error: any) => {
    // Handle request error
    return Promise.reject(error);
  }
);




axiosWithTokenFileUpload.interceptors.request.use(
    async (config: any) => {
      const token = getAuth();
  
      const currentTime = dayjs().tz("Asia/Kolkata");
      const specificDayjsTime = dayjs(token?.accessTokenExp).tz("Asia/Kolkata");
      // Do something before each request, like adding authentication headers
      config.headers = {
        Accept: "application/json",
       "Content-Type": "multipart/form-data",
        // "platform": "web"
      };
  
      if (
        specificDayjsTime.isSame(currentTime) ||
        specificDayjsTime.isBefore(currentTime)
      ) {
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const refreshResponse: any = await refreshToken();
  
            if (
              refreshResponse &&
              refreshResponse?.code === ENUM_STATUS_CODE?.SUCCESS
            ) {
              const newAccessToken = refreshResponse?.data?.tokens?.access?.token;
              config.headers["Authorization"] = `Bearer ${newAccessToken}`;
              onRrefreshed(newAccessToken);
            } else {
              // console.error("Failed to refresh the token");
              throw new Error(refreshResponse?.message || "Token refresh failed");
            }
          } catch (err) {
            // console.error("Error refreshing the token", err);
            return Promise.reject(err);
          } finally {
            isRefreshing = false; // Reset the flag
          }
        } else {
    
          return new Promise((resolve) => {
            addRefreshSubscriber((newToken: string) => {
              config.headers["Authorization"] = `Bearer ${newToken}`;
              resolve(config);
            });
          });
        }
      } else {
        // If the token is still valid, proceed with the current access token
        config.headers["Authorization"] = `Bearer ${token?.admin_token}`;
      }
      // config.headers['Authorization'] = `Bearer ${token?.admin_token}`;
      return config;
    },
    (error: any) => {
      // Handle request error
      return Promise.reject(error);
    }
  );

// axiosWithTokenFileUpload.interceptors.request.use(
//   (config: any) => {
//     const token = getAuth();
//     // Do something before each request, like adding authentication headers
//     config.headers = {
//       Accept: "application/json",
//       "Content-Type": "multipart/form-data",
//       Authorization: `Bearer ${token?.admin_token}`,
//       // "platform": "web"
//     };
//     // config.headers['Authorization'] = `Bearer ${token?.admin_token}`;
//     return config;
//   },
//   (error: any) => {
//     // Handle request error
//     return Promise.reject(error);
//   }
// );

axiosWithTokenAuth.interceptors.request.use(
  (config: any) => {

    config.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",

      // "platform": "web"
    };


    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);
