import { useFormik } from 'formik';
import React, { useState } from 'react'

import { LoginInitialVal, LoginProps, LoginValidation } from './modules';

import InputPassword from 'src/components/commons/InputComponent/InputPassword';
import { Checkbox } from 'src/components/ui/checkbox';
import { Label } from 'src/components/ui/label';
import { Link, useNavigate } from 'react-router-dom';

import LInputComp from 'src/components/commons/InputComponent/LInputComp';
import { Smartphone } from 'lucide-react';
import FormSubmitBtn from 'src/components/commons/Buttons/FormSubmitBtn';
import { updateError, updateErrorCode } from 'src/redux/Reducers/errorHadlingSlicer';
import { ENUM_STATUS_CODE } from 'src/helpers/index';
import axios, { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { loginApi } from 'src/services/services';
import { updateLogin, updateLoginData, updatePhoneNumber } from 'src/redux/Reducers/loginSlicer';
import { useAuth } from '../../Core';
import { encryptData } from 'src/Utility/crypto';

const Login = () => {
    const [loading,setLoading]=useState<boolean>(false)
    const { setCurrentUser, saveAuth,saveCurrent } = useAuth()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // const { toast } = useToast()
    const formik = useFormik<LoginProps>({
      initialValues: LoginInitialVal,
      onSubmit: async (values, { resetForm, setValues }) => {
        // console.log('Form Values:', values);
  
        const postData = {
          // phone: values.phone,
          password:encryptData(values.password),
          // type: "phone",
          // consumerNo: null,
          email:values.email
        }
        if (postData) {
          dispatch(updatePhoneNumber(values.email))
          dispatch(updateLogin(postData))
          henadleSendOtpApi(postData)
        }
      },
  
      validationSchema: LoginValidation
    })
  

  
    const henadleSendOtpApi = async (postData: any) => {
      setLoading(true)
      try {
        let apiResp = await loginApi(postData);
        dispatch(updateErrorCode(apiResp.code))
        if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
          // navigate('login/otp')

          saveCurrent({
            // ...apiResp?.data?.user,
            userResponse: apiResp?.data?.user,
            isAuthenticated: true,
            user_id: apiResp?.data?.user?.id,
            role:apiResp?.data?.user?.role
            // ...apiResp?.data
        })
        
        saveAuth({
            admin_token: apiResp.data?.tokens?.access?.token,
            accessTokenExp:apiResp.data?.tokens?.access?.expires,
            refreshTokenExp:apiResp.data?.tokens?.refresh?.expires,
            refreshToken:apiResp.data?.tokens?.refresh?.token,
            user_id: apiResp?.data?.user?.id,
            // consumerType: apiResp?.data?.user?.consumerType,
            // isLoadDetailsAvl: apiResp?.data?.user?.isLoadDetailsAvl,
            // isMeterDetailsAvl: apiResp?.data?.user?.isMeterDetailsAvl,
            // isPlantDetailsAvl: apiResp?.data?.user?.isPlantDetailsAvl ? apiResp?.data?.user?.isPlantDetailsAvl : false,
            user_name: apiResp?.data?.user?.username,
            isVerified: apiResp?.data?.user?.isVerified,
            role:apiResp?.data?.user?.role,
            disom:apiResp?.data&&apiResp?.data?.user?.disom,
            countryCode:apiResp?.data&&apiResp?.data?.countryCode,
            state:apiResp?.data&&apiResp?.data?.state,
            userCode:apiResp?.data&&apiResp?.data?.user?.usercode
            
        })
          dispatch(updateLoginData(apiResp?.data))
  
          formik.resetForm();
          formik.setValues(LoginInitialVal)
        } else {
        dispatch(updateError(apiResp.message))
        dispatch(updateErrorCode(apiResp.code))
          // toast.error(apiResp.message)
        }
        return apiResp.data
      } catch (err) {
        let error = err as Error | AxiosError
        if (axios.isAxiosError(error)) {
          dispatch(updateError("An unexpected error occurred. Please try again later."))
        dispatch(updateErrorCode(error.response?.data.code))
          // toast.error(error.response?.data.message)
        }
        else {
          dispatch(updateError("An unexpected error occurred. Please try again later."))
        //   dispatch(updateErrorCode(error.response?.data.code))
          // toast.error(error.message)
        }
      } finally {
      setLoading(false); // Set loading state to false when request completes (whether success or failure)
    }
    }

    return (
        <div className='w-full'>
                  <div className='w-full flex items-center space-x-4'>
        <div className='w-2 h-4 bg-yellowDark'></div>
        <div className='text-white test-sm font-bold uppercase'>Login</div>
      </div>
            <form className='w-full' onSubmit={formik.handleSubmit}>
 
            <div className='w-full  md:flex md:flex-col md:items-center'>
      
      <div className='w-full md:w-3/4 -mt-8'>
        <LInputComp iconName={(className: string) => <Smartphone className='lg:w-5 lg:h-5  w-6 h-6 md:mr-2 -mt-3' />}
          // label='Enter Phone Number'
          placeholder='Enter Email'
          id='email'
          type='text'
          // maxLength={10}
          // regex={new RegExp('/[^0-9]+/g')}
          errorMessage={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
          value={formik.values.email}
          onchange={(e: any) => formik.setFieldValue('email', e)}

        />
      </div>
      <div className='w-full md:w-3/4 -mt-4'>
      <InputPassword
             
             id='resetPassword'

             placeholder='Confirm Password'
             errorMessage={formik.touched.password && formik.errors.password ? formik.errors.password : ''}
             onchange={(value: string) => {
               formik.setFieldValue('password', value)
             }}
             values={formik.values.password}
             {...formik.getFieldProps('password')}
           />
      </div>
      
      {/* <div className='w-full md:w-3/4 flex justify-between items-center'>
        <div className='w-full md:w-3/4'>
          <div className='flex  items-center ml-4 mt-4 mb-2  '>
            <Checkbox id='checks'
              {...formik.getFieldProps('checkvalue')}
              checked={formik.values.checkvalue === 'yes'}
              onCheckedChange={() => formik.setFieldValue('checkvalue', 'yes')}
              className=' border border-input border-grayLable'
              data-qa='checkbox' />
            <Label htmlFor='check' className='text-xs md:text-sm text-[#212529] font-normal ml-2 lg:text-sm'>Remember Me</Label>

          </div>
          <p className='text-xs  text-red-500 ml-4 md:ml-10  '>{formik.touched.checkvalue && formik.errors.checkvalue ? formik.errors.checkvalue : ''} </p>
        </div>

        <Link className='text-[#212529] text-sm font-normal whitespace-nowrap hover:underline cursor-pointer hover:underline-offset-4 px-2' to={'/auth/forgot-password'}>Forgot Password</Link>
      </div> */}
      <div className=' w-full md:w-3/4 mt-2'>
        <FormSubmitBtn btnName='Login' data-qa='loginbtn' type='submit'
          // disabled={!formik.isValid || formik.isSubmitting} 
          id='login' loading={loading}/>
      </div>

      {/* <p className='md:w-3/4 mt-4 text-center text-zinc-600 text-xs md:text-sm font-medium lg:text-sm '>Don't have account? <span className='font-bold text-text_green_1'><Link to='/auth/register'>Sign Up</Link></span></p> */}
    </div>
 



        
            </form >
        </div >
    )
}

export default Login
